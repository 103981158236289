import React, { Component } from 'react'
import './Bio.css'

class Bio extends Component {
  render() {
    return (
      <section id="bio" className="Bio page-section">
        <div className="container">
          <div className="row">
            <hgroup className="col-12">
              <h2 className="page-section__title">Bio</h2>
            </hgroup>
          </div>

          <div className="row">
            <figure className="col-12 col-md-6">
              <figcaption>
                <h3>Alejo Aguirre Saravia</h3>
              </figcaption>
              <img
                className="bio-photo"
                src="/img/alejo-aguirre.jpg"
                alt="Me, working"
              />
              <div className="bio-links">
                <a
                  className="link-default"
                  href="https://www.linkedin.com/in/alejoaguirresaravia"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="My Linkedin"
                >
                  <svg className="icon" x="0px" y="0px" viewBox="0 0 510 510">
                    <path
                      d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
                      M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
                      S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
                      V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"
                    />
                  </svg>
                </a>
                <a
                  className="link-default"
                  href="Resume%20Alejo%20Aguirre.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="My Resume"
                >
                  <svg className="icon" x="0px" y="0px" viewBox="0 0 420 420">
                    <path
                      d="M380.95,79.002c-0.024-0.243-0.066-0.481-0.107-0.719c-0.015-0.083-0.022-0.168-0.038-0.251
                      c-0.057-0.287-0.131-0.568-0.213-0.846c-0.01-0.033-0.016-0.067-0.025-0.099c-0.086-0.282-0.188-0.558-0.297-0.829
                      c-0.012-0.03-0.021-0.061-0.033-0.09c-0.106-0.256-0.227-0.504-0.354-0.748c-0.023-0.045-0.042-0.093-0.066-0.138
                      c-0.118-0.219-0.249-0.429-0.382-0.638c-0.043-0.067-0.08-0.138-0.124-0.204c-0.126-0.188-0.265-0.366-0.403-0.544
                      c-0.062-0.08-0.117-0.164-0.182-0.243c-0.162-0.197-0.337-0.383-0.513-0.567c-0.049-0.051-0.091-0.106-0.141-0.156l-70-70
                      c-0.047-0.047-0.1-0.088-0.148-0.134c-0.187-0.179-0.375-0.356-0.575-0.52c-0.074-0.061-0.155-0.114-0.231-0.172
                      c-0.183-0.142-0.365-0.284-0.557-0.412c-0.062-0.042-0.128-0.076-0.191-0.116c-0.213-0.136-0.427-0.27-0.651-0.389
                      c-0.042-0.022-0.086-0.04-0.128-0.062c-0.247-0.128-0.498-0.25-0.757-0.358c-0.028-0.012-0.058-0.02-0.086-0.032
                      c-0.272-0.11-0.549-0.212-0.833-0.298c-0.033-0.01-0.068-0.016-0.102-0.026c-0.277-0.081-0.557-0.155-0.843-0.212
                      c-0.084-0.017-0.17-0.024-0.254-0.039c-0.237-0.041-0.474-0.083-0.716-0.107C301.668,0.017,301.335,0,301,0H49
                      c-5.522,0-10,4.477-10,10v400c0,5.523,4.478,10,10,10h322c5.522,0,10-4.477,10-10V80C381,79.665,380.983,79.332,380.95,79.002z
                      M311,34.142L346.858,70H311V34.142z M59,400V20h232v60c0,5.523,4.478,10,10,10h60v310H59z"
                    />
                    <path d="M129,154c0,5.523,4.478,10,10,10h64c5.522,0,10-4.477,10-10s-4.478-10-10-10h-64C133.478,144,129,148.477,129,154z" />
                    <path
                      d="M201,104c0-16.542-13.458-30-30-30s-30,13.458-30,30s13.458,30,30,30S201,120.542,201,104z M161,104
                      c0-5.514,4.486-10,10-10s10,4.486,10,10s-4.486,10-10,10S161,109.514,161,104z"
                    />
                    <path
                      d="M99,204h144c5.522,0,10-4.477,10-10v-31.892c0-5.523-4.478-10-10-10s-10,4.477-10,10V184H109V60h124v68
                      c0,5.523,4.478,10,10,10s10-4.477,10-10V50c0-5.523-4.478-10-10-10H99c-5.522,0-10,4.477-10,10v144C89,199.523,93.478,204,99,204z"
                    />
                    <path d="M321,234H99c-5.522,0-10,4.477-10,10s4.478,10,10,10h222c5.522,0,10-4.477,10-10S326.522,234,321,234z" />
                    <path d="M321,284H99c-5.522,0-10,4.477-10,10s4.478,10,10,10h222c5.522,0,10-4.477,10-10S326.522,284,321,284z" />
                    <path d="M321,334H99c-5.522,0-10,4.477-10,10s4.478,10,10,10h222c5.522,0,10-4.477,10-10S326.522,334,321,334z" />
                  </svg>
                </a>
              </div>
            </figure>

            <div className="col-12 col-md-6 bio-text">
              <h5>Developing Full-Life</h5>
              <p>
                Hi! I'm Alejo, a full life Web Developer, what makes me be
                always in touch with the latest technologies and good practices.
              </p>
              <p>
                I started working as a Front End Developer in 2013, and been
                quite involved in the area since then. I've built some cool
                stuff with HTML5, CSS3, JavaScript and PHP, focused on "mobile
                first" responsive development and scalable code, working with
                clients from around the globe.
              </p>
              <p>
                The work I provide is of highest quality, with UX and SEO
                criteria applied, fully responsive, and tested in a wide range
                of devices. If you’d like to find out more about how I work,
                please{' '}
                <a className="link-default" href="#contact">
                  contact me
                </a>{' '}
                and give a little information about the project you have in
                mind. We can then set up a call to discuss how I can be of most
                value to your project.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Bio
