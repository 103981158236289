import React, { Component } from 'react'
import './Hero.css'

class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      texts: ['JS Lover', 'PHP Enthusiast', 'HTML & CSS based'],
      currentIndex: 0,
      currentText: 'JS Lover'
    }
  }

  componentDidMount() {
    setTimeout(this.rewriteNext, 2500)
  }

  rewriteNext = () => {
    this.rewriteReset()
    const interval = setInterval(() => {
      const currentText = this.state.currentText
      const originalText = this.state.texts[this.state.currentIndex]
      this.setState({
        currentText: currentText + originalText.charAt(currentText.length)
      })
      if (currentText === originalText) {
        clearInterval(interval)
        setTimeout(() => {
          this.rewriteNext()
        }, 2500)
      }
    }, 125)
  }

  rewriteReset = () =>
    this.setState({
      currentText: '',
      currentIndex:
        this.state.currentIndex < this.state.texts.length - 1
          ? this.state.currentIndex + 1
          : 0
    })

  render() {
    return (
      <section className="Hero">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <hgroup>
                <h1 className="title-main">
                  <span>Hi,</span> <span>I'm Alejo</span> Web Developer
                </h1>
                <h3 className="rewrittable">{this.state.currentText}</h3>
              </hgroup>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Hero
