import React, { Component } from 'react'
import './Header.css'

class Header extends Component {
  onLinkClick = e => {
    e.preventDefault()
    const selector = e.target.getAttribute('href')
    const section = document.querySelector(selector)
    const offset = section
      ? window.pageYOffset + section.getBoundingClientRect().top
      : 0
    this.scrollTo(offset)
  }

  scrollTo = offset => {
    const cosParameter = (window.pageYOffset - offset) / 2
    const scrollDuration = 800
    let scrollCount = 0
    let oldTimestamp = performance.now()
    let isScrolling
    const setIsScrolling = () => {
      isScrolling = true
    }
    document.addEventListener('touchstart', setIsScrolling)
    document.addEventListener('mousewheel', setIsScrolling)
    const step = newTimestamp => {
      scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
      if (isScrolling || scrollCount >= Math.PI) {
        document.removeEventListener('touchstart', setIsScrolling)
        document.removeEventListener('mousewheel', setIsScrolling)
        return
      }
      window.scrollTo(
        0,
        Math.round(offset + cosParameter + cosParameter * Math.cos(scrollCount))
      )
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }
    window.requestAnimationFrame(step)
  }

  render() {
    return (
      <header className="Header">
        <div className="container">
          <div className="row">
            <div className="col">
              <h4 className="logo">&lt; Alejo &gt;</h4>
            </div>
            <nav className="col">
              <ul>
                <li className="show-tablet">
                  <a
                    className="link-default h5"
                    href="#work"
                    onClick={this.onLinkClick}
                  >
                    Work
                  </a>
                </li>
                <li className="show-tablet">
                  <a
                    className="link-default h5"
                    href="#bio"
                    onClick={this.onLinkClick}
                  >
                    Bio
                  </a>
                </li>
                <li className="show-tablet">
                  <a
                    className="link-default h5"
                    href="#contact"
                    onClick={this.onLinkClick}
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    className="link-default"
                    href="https://www.linkedin.com/in/alejoaguirresaravia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="icon block"
                      x="0px"
                      y="0px"
                      viewBox="0 0 510 510"
                    >
                      <path
                        d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
                          M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
                          S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
                          V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
