import React, { Component } from 'react'
import WorkInfo from './WorkInfo'
import WorkPreview from './WorkPreview'
import './Works.css'

class Works extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showInfo: false,
      showPreview: false,
      works: [
        {
          slug: 'little-blue',
          title: 'Little Blue - Buzos de Egresados',
          description: "In charge of the new site's front-end development.",
          skills: [
            'HTML5',
            'CSS3',
            'JavaScript',
            'jQuery',
            'Bootstrap',
            'PHP',
            'Laravel'
          ],
          link: '//littleblue.com.ar'
        },
        {
          slug: 'am',
          title: 'Aston Martin Residences',
          description:
            'Developed a whole wordpress theme, fully customized for the client through the use of Gulp.',
          skills: ['Wordpress', 'SCSS', 'JavaScript', 'jQuery', 'PHP'],
          link: 'http://astonmartinresidences.com'
        },
        {
          slug: 'revolucion-rusa',
          title: 'Revolución Rusa - 100 años',
          description:
            'Front-End Developer. In charge of JavaScript development. Server communication via AJAX.',
          skills: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'AJAX'],
          link: '//laizquierdadiario.com/revolucion-rusa'
        },
        {
          slug: 'chelagarto',
          title: 'Che Lagarto Hostels',
          description: 'Various landing pages development and AB Testing.',
          skills: ['HTML5', 'CSS3', 'JavaScript', 'jQuery']
        },
        {
          slug: 'isacasadete',
          title: 'ISA Casa de Té',
          description:
            "Development of the new site taking into account the client's needs.",
          skills: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'PHP', 'Wordpress'],
          link: '//isacasadete.com'
        },
        {
          slug: 'tykio',
          title: 'Tykio',
          description: 'Co-founder and Web Developer of Digital Agency.',
          skills: [
            'Project Managment',
            'HTML5',
            'CSS3',
            'JavaScript',
            'jQuery',
            'Bootstrap',
            'PHP',
            'Wordpress'
          ],
          link: '//tykio.com'
        }
      ]
    }
  }

  openInfo = work => this.setState({ showInfo: work })

  openPreview = work => this.setState({ showPreview: work })

  render() {
    return (
      <section id="works" className="Works page-section">
        <div className="container">
          <div className="row">
            <hgroup className="col-12">
              <h2 className="page-section__title">Work</h2>
              <h4>I'd really love you to be here</h4>
            </hgroup>
          </div>
          <div className="row">
            {this.state.works.map((work, i) => (
              <div key={i} className="work col-12 col-md-6">
                <figure
                  style={{
                    backgroundImage: `url(/img/works/${work.slug}.jpg)`
                  }}
                >
                  <figcaption>
                    <h4 className="work-title">{work.title}</h4>
                    <div className="icon-wrapper">
                      <svg
                        className="icon h4"
                        x="0px"
                        y="0px"
                        viewBox="0 0 446.25 446.25"
                        onClick={() => this.openPreview(work)}
                      >
                        <path
                          d="M318.75,280.5h-20.4l-7.649-7.65c25.5-28.05,40.8-66.3,40.8-107.1C331.5,73.95,257.55,0,165.75,0S0,73.95,0,165.75
                          S73.95,331.5,165.75,331.5c40.8,0,79.05-15.3,107.1-40.8l7.65,7.649v20.4L408,446.25L446.25,408L318.75,280.5z M165.75,280.5
                          C102,280.5,51,229.5,51,165.75S102,51,165.75,51S280.5,102,280.5,165.75S229.5,280.5,165.75,280.5z"
                        />
                      </svg>
                    </div>
                    <div className="icon-wrapper">
                      <svg
                        className="icon h4"
                        x="0px"
                        y="0px"
                        viewBox="0 0 23.625 23.625"
                        onClick={() => this.openInfo(work)}
                      >
                        <path
                          d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                          S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                          c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                          l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                          c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                          c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                          c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                          c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                          c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                          M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                          c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                          c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z"
                        />
                      </svg>
                    </div>
                    {work.link && (
                      <a
                        className="icon-wrapper"
                        href={work.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          className="icon h4"
                          x="0px"
                          y="0px"
                          viewBox="0 0 80 80"
                        >
                          <path
                            d="M29.298,63.471l-4.048,4.02c-3.509,3.478-9.216,3.481-12.723,0c-1.686-1.673-2.612-3.895-2.612-6.257
                            s0.927-4.585,2.611-6.258l14.9-14.783c3.088-3.062,8.897-7.571,13.131-3.372c1.943,1.93,5.081,1.917,7.01-0.025
                            c1.93-1.942,1.918-5.081-0.025-7.009c-7.197-7.142-17.834-5.822-27.098,3.37L5.543,47.941C1.968,51.49,0,56.21,0,61.234
                            s1.968,9.743,5.544,13.292C9.223,78.176,14.054,80,18.887,80c4.834,0,9.667-1.824,13.348-5.476l4.051-4.021
                            c1.942-1.928,1.953-5.066,0.023-7.009C34.382,61.553,31.241,61.542,29.298,63.471z M74.454,6.044
                            c-7.73-7.67-18.538-8.086-25.694-0.986l-5.046,5.009c-1.943,1.929-1.955,5.066-0.025,7.009c1.93,1.943,5.068,1.954,7.011,0.025
                            l5.044-5.006c3.707-3.681,8.561-2.155,11.727,0.986c1.688,1.673,2.615,3.896,2.615,6.258c0,2.363-0.928,4.586-2.613,6.259
                            l-15.897,15.77c-7.269,7.212-10.679,3.827-12.134,2.383c-1.943-1.929-5.08-1.917-7.01,0.025c-1.93,1.942-1.918,5.081,0.025,7.009
                            c3.337,3.312,7.146,4.954,11.139,4.954c4.889,0,10.053-2.462,14.963-7.337l15.897-15.77C78.03,29.083,80,24.362,80,19.338
                            C80,14.316,78.03,9.595,74.454,6.044z"
                          />
                        </svg>
                      </a>
                    )}
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>

        {this.state.showInfo && (
          <WorkInfo
            work={this.state.showInfo}
            close={() => this.setState({ showInfo: false })}
          />
        )}

        {this.state.showPreview && (
          <WorkPreview
            work={this.state.showPreview}
            close={() => this.setState({ showPreview: false })}
          />
        )}
      </section>
    )
  }
}

export default Works
