import React, { Component } from 'react'
import './Contact.css'

class Contact extends Component {
  render() {
    return (
      <section id="contact" className="Contact page-section">
        <div className="container">
          <div className="row">
            <hgroup className="col-12">
              <h2 className="page-section__title">Contact</h2>
            </hgroup>
            <div className="col-12 contact-content">
              <div className="h2">
                <a href="tel:+5491162308996" className="link-default">
                  +54 911 6230 8996
                </a>
              </div>

              <div className="h2 mail-block">
                <a href="mailto:hi@alejoas.com" className="link-default">
                  hi@alejoas.com
                </a>
              </div>

              <div className="h3">
                <p>Buenos Aires, Argentina</p>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <p>
            &copy; Copyright 2017 Alejo Aguirre Saravia, All Rights Reserved.
          </p>
        </footer>
      </section>
    )
  }
}

export default Contact
