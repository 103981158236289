import React, { Component } from 'react'
import './WorkPreview.css'

class WorkPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'laptop',
      shake: true
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.checkKeyCode)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.checkKeyCode)
  }

  checkKeyCode = e => e.keyCode === 27 && this.props.close()

  toggleView = () =>
    this.setState(state => ({
      view: ['mobile', 'laptop'].filter(x => x !== state.view)[0],
      shake: false
    }))

  render() {
    return (
      <div
        className={`WorkPreview ${this.state.view}`}
        onClick={e => e.currentTarget === e.target && this.props.close()}
      >
        <div
          className={`toggle-view link-default ${
            this.state.shake ? 'shake' : ''
          }`}
          onClick={this.toggleView}
        >
          <svg className="icon" x="0px" y="0px" viewBox="0 0 97.385 97.385">
            <path
              className="bottom"
              d="M97.265,71.261c-0.093-0.108-0.23-0.172-0.374-0.172H0.494c-0.144,0-0.28,0.063-0.374,0.172
              c-0.094,0.108-0.136,0.253-0.115,0.396c0.014,0.09,1.468,9.016,12.753,9.016h71.869c11.284,0,12.738-8.926,12.753-9.016
              C97.4,71.514,97.358,71.369,97.265,71.261z M55.942,77.547h-14.5v-3.334h14.5V77.547z"
            />
            <path
              className="square"
              d="M10.507,67.619h76.37c1.104,0,2-0.896,2-2V18.713c0-1.104-0.896-2-2-2h-76.37c-1.104,0-2,0.896-2,2v46.906
              C8.507,66.723,9.403,67.619,10.507,67.619z M15.507,23.713h66.37v36.906h-66.37V23.713z"
            />
            <circle className="circle" cx="54.5" cy="74" r="4" />
          </svg>
        </div>

        <div className="close link-default" onClick={this.props.close}>
          <svg className="icon" x="0" y="0" viewBox="0 0 191.414 191.414">
            <path
              d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045
              c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181
              c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74
              c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z"
            />
          </svg>
        </div>

        <div
          className="modal"
          style={{
            backgroundImage: `url(/img/${this.state.view}.png)`
          }}
        >
          <div className="inner">
            <img
              src={`img/works/${this.props.work.slug}-${this.state.view}.jpg`}
              alt="Work preview"
            />
          </div>
        </div>
      </div>
    )
  }
}

export default WorkPreview
