import React, { Component } from 'react'
import './WorkInfo.css'

class WorkInfo extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.checkKeyCode)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.checkKeyCode)
  }

  checkKeyCode = e => e.keyCode === 27 && this.props.close()

  render() {
    return (
      <div
        className="WorkInfo"
        onClick={e => e.currentTarget === e.target && this.props.close()}
      >
        <div className="modal">
          <header>
            <h4>{this.props.work.title}</h4>
            <svg
              className="icon link-default"
              x="0"
              y="0"
              viewBox="0 0 191.414 191.414"
              onClick={this.props.close}
            >
              <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z" />
            </svg>
          </header>
          <div className="body">
            <p>{this.props.work.description}</p>
            <ul>
              {this.props.work.skills.map((skill, i) => (
                <li key={i}>{skill}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkInfo
