import React, { Component } from 'react'
import Hero from './components/Hero/Hero'
import Header from './components/Header/Header'
import Works from './components/Works/Works'
import Bio from './components/Bio/Bio'
import Contact from './components/Contact/Contact'

class App extends Component {
  render() {
    return (
      <div className="App">
        <Hero />
        <Header />
        <Works />
        <Bio />
        <Contact />
      </div>
    )
  }
}

export default App
